import React, { Component } from "react";
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { TOC, TocItem } from "./App"

import midi_1 from "./static/images/midi_1.PNG"
import midi_2 from "./static/images/midi_2.PNG"
import midi_3 from "./static/images/midi_3.PNG"
import synergi_1 from "./static/images/homepage.PNG"
import synergi_2 from "./static/images/login.PNG"
import synergi_3 from "./static/images/projects.PNG"
import synergi_4 from "./static/images/github.PNG"
import synergi_5 from "./static/images/create_new.png"
import synergi_6 from "./static/images/editor.PNG"
import gan_1 from "./static/images/gan_1.JPG"
import gan_2 from "./static/images/gan_2.JPG"
import gan_3 from "./static/images/gan_3.JPG"
import gan_4 from "./static/images/gan_4.JPG"
import gan_5 from "./static/images/gan_5.JPG"
import gan_6 from "./static/images/gan_6.JPG"
import 'katex/dist/katex.min.css';
import { BlockMath, InlineMath } from 'react-katex';
class Projects extends Component {
  render() {
    return (
      <div id="Projects">
        <p className="content_title">Projects</p>
        <TOC>
          <TocItem href='#srgan'>SRGAN</TocItem>
          <TocItem href='#nlp'>Fake-Review Detection</TocItem>
          <TocItem href='#gbt'>Loan Prediction</TocItem>

          <TocItem href="#midi">Midi File Parser</TocItem>
          <TocItem href="#synergi">Synergi</TocItem>
          <TocItem href="#music_maker">Music Maker</TocItem>
          <TocItem href="#conway">Conway's Game of Life</TocItem>
          <TocItem href="#discord_bot">Discord Bot</TocItem>
          <TocItem href="#rock_paper">Rock Paper Scissors</TocItem>
          <TocItem href="#portfolio">Portfolio</TocItem>
        </TOC>
        <div className="content">
          <Project id='srgan'>
            <div className="title">Image Enhancing with GANs</div>
            <a className="github" href="https://github.com/durbin3/cs390-final_project">Github Repo</a>
            <div className="tech_title">Description:</div>
            <div className="text">
              Image enhancing, a technology once reserved to NCIS,
              is possible through the use of GANs (Generative Adversarial Networks).
              GANs are constructed by taking two Convolutional Neural Networks (CNNs)
              and pitting them against each other in a never ending game of trying 
              to outsmart the other. One CNN (the generator) is attempting to generate high
              resolution images from the given low resolution image, and the other
              CNN (the discriminator) is attempting to detect whether a given image
              is real, or generated by the Generator. Through their competition, 
              the Generator will start generating more convincing images, and the
              Discriminator will get better at noticing the flaws that make the
              image appear fake. Ultimately if the Generator wins the competition, 
              the Discriminator will be unable to determine which images are fake,
              and which are real. 
            </div>
            <div className="text">
              Below are pictures of the successful Super Resoluted images (SR), 
              compared with their low resolution (LR) and their high resolution (HR)
              counterparts.
            </div>
            <Images className='gan_slideshow'>
              <Image i='1' url={gan_1}/>
              <Image i='2' url={gan_2}/>
              <Image i='3' url={gan_3}/>
              <Image i='4' url={gan_4}/>
              <Image i='5' url={gan_5}/>
              <Image i='6' url={gan_6}/>
            </Images>
            <div className="tech_title">Technologies Used:</div>
            <div className="text">
              Python, Tensorflow, Keras
            </div>
          </Project>
          <Project id='gbt'>
            <div className="title">Loan Repayment Prediction</div>
            <a className="github" href="https://github.com/durbin3/bank-prediction">Github Repo</a>
            <div className="tech_title">Description:</div>
            <div className="text">
              The prediction task was to accurately predict whether a person will 
              repay a bank loan based off of given data. The training data was composed of 25 columns of data, such as the amount being requested,
              the race of the requester, the duration of the loan, and also a binary value of 0 if the person had repaid the
              loan, or a 1 if the person had not. The test data was comprised of the same columns, excluding the true
              prediction of loan repayment.
            </div>

            <div className="text">The model used for prediction was a Gradient Boosted Decision Tree. The model space is the set
of all trees that can be used. The input to the model were the following features, with numeric features
normalized and categorical features converted to One-Hot encoding: requested amount, annual income,
revolving balance, debt to income ratio, total revolving limit, months since last delinquency, loan
duration, employment length, income to balance ratio, balance to limit ratio, requested amount to income
ratio, income to revolving limit ratio, and home ownership status. The output of the model was a
probability that the person would repay the loan, which would be translated to a 1 if the probability was
greater than or equal to .5, or a 0 otherwise.
</div>
            <div className="text">Gradient Boosting sequentially trains trees, so there are two different score functions, one to
evaluate the split of the tree and one to fit the trees through gradient descent. The splits are evaluated
using Friedman MSE, which is given by the following function, with <InlineMath math='w_r,w_l'/>
being the weights of the right
and left sides of the splits, and <InlineMath math='S_r,S_l'/> being the sums of the right and left sides of the splits:
</div>
            <BlockMath math='Diff=w_rS_l-w_lS_r'/>
            <BlockMath math='Improvement=\frac{Diff^2}{w_rw_l}'/>
            <div className="text">Alternatively, the equation can be simplified to the following closed form:
</div>
            <BlockMath math='Improvement=\frac{w_lw_r}{(w_l+w_r)(\bar{\mu}_l-\bar{\mu}_r)^2}'/>

            <div className='text'>
            The score function to guide gradient descent of the model is the statistical deviance of the log-likelihood,
given with the following formula:
            </div>
            <BlockMath>Dev=-2*LL(x,y)</BlockMath>
            <BlockMath math='LL(x,y)=\sum_{i=1}^n y_i \log\left(\sigma(w^Tx_i+b)\right)+ (1-y_i) \log\left(1-\sigma(w^Tx_i+b)\right)'/>
            
            <div className="text">
            The model minimized the loss through gradient descent of the binomial deviance function, and through
boosting by sequential training of decision trees created by evaluation by Friedman MSE. The model was
then evaluated over the Mathews Correlation Coefficient (MCC) on a validation data set, to gauge
accuracy on the test data. The baseline score for random guessing is a score of 0.0, and the final test MCC
score was 0.17393.
            </div>
          </Project>
          <Project id='nlp'>
            <div className="title">Fake Review Detection</div>
            <a className="github" href="https://github.com/durbin3/fake-review-detection">Github Repo</a>
            <div className="tech_title">Description:</div>
            <div className="text">The first task for prediction was to predict whether a given review for an online store product was
fake or real given a handful of data. The training data was composed of: the review itself, a numeric
rating from 1-5 (inclusive), the category the review was posted to, and a value indicating whether the
review was fake (0) or real (1). The test dataset was identical to the training set in terms of categories,
except for the exclusion of the true values of whether the review was fake. </div>
            <div className="text">The model used for prediction was a logistic regression (LR) model, with L2 regularization. The
model space was represented by the value of the weight and bias of the LR. The input to the model was a
vectorized list of TF-IDF features generated from the words in the reviews of the test dataset, along with
the rating – not normalized, to give it slightly more weight in the LR. The output of the model was a
probability that the review is fake, which would be translated to a 1 if the probability was greater than or
equal to .5, or a 0 otherwise.</div>

          <div className="text">The score function of the model is the negative log-likelihood with L2 regularization, given with
the following formula:</div>
          <BlockMath math='NLL=-C\sum_{i=1}^n y_i \log\left(\sigma(w^Tx_i+b)\right)+ (1-y_i) \log\left(1-\sigma(w^Tx_i+b)\right)+\frac{1}{2}w^Tw'/>
          <div className="text">The logistic regression minimized the negative log likelihood using gradient descent over the likelihood
function to produce a possible model, and each model was evaluated over the AUC score on a validation
dataset to gauge the accuracy of the model on the test set. The baseline score for random guessing is a
score of 0.50, and the final test AUC score was 0.9124.</div>
          </Project>
        <Project id="midi">
            <div className="title">Midi File Parser</div>
            <div>
            <Images className="midi_slideshow">
              <Image i="1" url={midi_1}/>
              <Image i="2" url={midi_2}/>
              <Image i="3" url={midi_3}/>
            </Images>
              <div className="tech_title">Description:</div>
              <div className="text">
                The Midi file Parser can read in a directory,
                and after populating the gui with the .midi files,
                can perform modifications on the song,
                and can save the resulting song to the local computer.
              </div>
              <div className="text">
                Behind the scenes, the midi file is parsed and loaded into
                memory using linked lists and binary trees.
              </div>
              <div className="tech_title">Technologies Used:</div>
              <div className="text">
                C, Midi API, GTK+ graphics library
              </div>
            </div>
          </Project>
          <Project id="synergi">
            <div className="title">Synergi</div>
            <a className="github" href="https://github.com/legalian/synergi">Github Repo</a>
            <Images className="syn_slideshow">
              <Image i="1" url={synergi_1}/>
              <Image i="2" url={synergi_2}/>
              <Image i="3" url={synergi_3}/>
              <Image i="4" url={synergi_4}/>
              <Image i="5" url={synergi_5}/>
              <Image i="6" url={synergi_6}/>
            </Images>
            <div className="tech_title">Description:</div>
            <div className="text">
                Synergi is a work-in-progress real time collaborative code editor with GitHub integration.
                The user can log into the website through GitHub, and after allowing api access to Synergi,
                the user can import a project from GitHub into Synergi. This will allow them to use the in-built
                code editor to edit any file in the imported repository in real time, at the same time as another user.
                Each user can see the updates in real time and can commit/push the changes to back to the GitHub repository.
            </div>
            <div className="text">
                Behind the scenes the file is synchronized in real time, with each change being verified to prevent
                desynchronization between users. The files themselves are loaded onto a server-side database with all the
                changes where they stay until they are pushed to GitHub.
            </div>
            <div className="text">
                The website itself is a flask app hosted on Heroku. It is constructed using
                Jinja templating and with a SQLAlchemy Database.
            </div>
            <div className="tech_title">Technologies Used:</div>
            <div className="text">
                Python, SQLAlchemy, PostgreSQL, HTML/CSS, Javascript, Jinja
            </div>
          </Project>
          <Project id="music_maker">
            <div className="title">Music Generator</div>
            <a className="github" href="https://github.com/durbin3/music-maker">Github repo</a>
            <div className="tech_title">Description:</div>
            <div className="text">
                The Music Generator can take in a time signature, tonality, and length and generate a song
                that follows music theory standards and has complex melody and rhythm. The inspiration for this project
                came from wanting to create an algorithm that would be able to generate an improvised jazz solo that would
                sound pleasing to hear.
            </div>
            <div className="text">
                Behind the scenes the notes are generated using directed graphs and the rhythms are generated by
                recursively subdividing trees.
            </div>
            <div className="tech_title">Technologies Used:</div>
            <div className="text">Java</div>
          </Project>
          <Project id="conway">
            <div className="title">Conway's Game of Life</div>
            <div className="tech_title">Description:</div>
            <div className="text">
                Solo project that recreated the cell evolution simulator conceptualized
                by John Conway in Java for the purpose of gaining experience with java
            </div>
            <div className="tech_title">Technologies Used</div>
            <div className="text">Java</div>
          </Project>
          <Project id="discord_bot">
            <div className="title">Discord Bot</div>
            <div className="tech_title">Description:</div>
            <div className="text">
                The Discord Bot is a smaller project where I created a program to parse the messages being sent
                in my friends Discord chatroom and if one of the messages contains a reference to a movie we have seen,
                the Bot will send a picture of the scene with the appropriate caption in the image.
            </div>
            <div className="tech_title">Technologies Used:</div>
            <div className="text">Javascript, Discord API</div>
          </Project>
          <Project id="rock_paper">
          <div className="title">Rock Paper Scissors</div>
          <div className="tech_title">Description:</div>
          <div className="text">
              The very first project I ever made, this is the game Rock, Paper, Scissors.
              The player can play with one or two players in the browser. Super simple, but I couldn't leave it
              out of my portfolio.
          </div>
          <div className="tech_title">Technologies Used:</div>
          <div className="text">Javascript, HTML/CSS</div>
        </Project>
        <Project id="portfolio">
            <div className="title">This Portfolio</div>
            <a className="github" href="https://github.com/durbin3/website">Github Repo</a>
            <div className="tech_title">Technologies Used (V 2.0):</div>
            <div className="text">
              ReactJS (Javascript), Flask (Python), HTML/CSS
            </div>
            <div className="tech_title">Technologies Used (V 1.0):</div>
            <div className="text">
              Jinja, Flask, HTML/CSS, Javascript
            </div>

          </Project>
        </div>
      </div>
    );
  }
}

const Image = (props) => {
  return (
    <div className="each-slide">
      <img src={props.url}></img>
    </div>
  );
}
class Images extends Component {
  render() {
    return (
      <div className={"slide-container " + this.props.className}>
        {/* <button className="button-left" onclick="plusDivs(-1)">&#10094;</button>
        <button className="button-right" onclick="plusDivs(+1)">&#10095;</button> */}
        <Slide className="slidee" autoplay={false}>
          {this.props.children}
        </Slide>
      </div>
    );
  }
};

const Project = (props) => {
  return (
    <div id={props.id} className="project">
      {props.children}
    </div>
  );
}


export default Projects;